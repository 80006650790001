.about {
    width: 100%;
    margin: 3rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
}
.about .left {
    text-align: center;
    margin: auto;
    padding: 1rem;
    max-width: 500px;
}
.about .left:hover{
    border: 1px solid #ff9700;
    border-radius: 10px;
}
.about .left p {
    color: black;
    line-height: 1.8;
    margin: 1.2rem 0;
}
.about .right {
    max-width: 700px;
}
.right .img-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
    align-items: center;
    text-align: center;
}
.right .img {
    max-width: 90%;
    border: 1px solid #ff9700;
}
.right .top {
    grid-column: 1 / span 8;
    grid-row: 1;
    padding-top: 20%;
    z-index: 6;
}
.right .bottom {
    grid-column: 4 / -1;
    grid-row: 1;
    z-index: 5;
}
.resume-btn {
    max-width: 600px;
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 2rem;
}
.resume-btn .btn {
    text-align: center;
    height: 100%;
    width: 300px;
    color: #ff9700;
}
.resume-btn .btn:hover {
    color: #fff;
}


@media screen and (max-width: 300px) {
    .resume-btn .btn{
        width: 100px;
        padding: auto;
    }
}