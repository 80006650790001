.project-heading {
    text-align: center;
    padding: 4rem 0 2rem 0;
}
.project-heading:hover {
    color: #ff9700;
}
.project-container {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    padding-bottom: 5rem;
}
.project-card {
    /* border: 1px solid #ff9700; */
    border-radius: 10px;
    background: #fff;
    padding: 1.2rem 1rem;
    display: flex;
    flex-direction: column; /* Stacks elements vertically */
    justify-content: space-between; /* Distributes space between children */
    height: 100%; /* Ensures all cards have the same height */
}
.project-card:hover {
    border: 1px solid #261fff;
    border-radius: 10px;
}
.project-card img {
    border-radius: 10px;
    width: 100%; /* Ensures image spans the full width of its container */
    height: 200px; /* Set a fixed height for all images */
    object-fit: cover; /* Keeps the aspect ratio while covering the area */
    display: block; /* Removes any inline spacing below the image */
    margin: auto; /* Centers the image horizontally if needed */
}
.project-card img:hover {
    opacity: 0.3;
}
.project-title {
    color: black;
    padding: 1rem 0;
    text-align: center;
}
.project-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.project-details p {
    color: black;
    padding-bottom: 1rem;
    font-size: 1.1rem;
    text-align: justify;
}
.project-date {
    color: #261fff;
    padding: 1rem 0;
    font-size: 1.0rem;
    text-align: center;
}
.project-btns {
    /* width: 60%; */
    margin-top: auto; /* Pushes buttons to the bottom of the container */
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    gap: 10px; /* Adds spacing between buttons */
}
.project-btns .btn {
    padding: 0.5rem 1rem;
}
.project-btns .btn-light {
    text-align: center;
    color: #ff9700;
}
.project-btns .btn-light:hover {
    color: #fff;
}

@media screen and (max-width: 480px) {
    .project-container {
        max-width: 480px;
        margin: auto;
        grid-template-columns: 1fr;
    } 
    .project-btns {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0.5rem 0;
        margin: auto;
    } 
    .project-btns .btn {
        margin-bottom: 1rem;
    }  
}

@media screen and (max-width: 767px) and ( min-width: 480px)  {
    .project-container {
        max-width: 90%;
        height: 100%;
        margin: auto;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(2, 1fr);
    }
    .project-btns .btn {
        margin-bottom: 1rem;
    } 
    .project-btns {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: auto;
        padding: 0.5rem 0;
    }   
}