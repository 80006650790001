.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    position: fixed;
    width: 100%;
    height: 90px;
    z-index: 10;
}
.header-bg {
    background-color: rgba(95, 66, 3, 0.9);
    transition: 0.5s;
}
.logo {
    position: flex;
    padding: 1rem;
    width: 100px;
    height: 100px;
}
.nav-menu {
    display: flex;
}

.nav-menu li {
    padding: 0 1rem;
}
.nav-menu li:hover{
    border-bottom: 3px solid #fff;
}
.nav-menu li a {
    font-size: 1.2rem;
    font-weight: 700;
    color: #fff;
}

/* Hamburger menu icon */
.hamburger {
    display: none;
}

/* Making the navlinks responsive */
@media screen and (max-width: 1040px) {
    .logo {
        /* align-items: center; */
        position: flex;
        padding: 0.5rem;
        width: 50px;
        height: 50px;
    }
    .nav-menu {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        background: rgba(95, 66, 3, 0.9);
        /* opacity: 0.7; */
        position: absolute;
        top: 0;
        left: -120%;
        z-index: -3;
        transition: 0.3s;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-menu li {
        padding: 1rem 0;
    }

    .nav-menu li a {
        font-size: 2rem;
    }

    .hamburger {
        display: initial;
    }
}