.contact {
    background-color: #fff;
    width: 100%;
    padding: 6rem 0;
}
.contact-container {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
}
.social-links {
    padding: 4rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    max-width: 600px;
}
.social-link:hover {
    background-color: #ff9700;
    padding: 2rem 1rem;
    opacity: 0.7;
}
.social-link {
    border: 1px solid black;
    border-radius: 10px;
    text-align: center;
    margin-top: 2rem;
    padding: 1rem;
}
.social-link h2:hover {
    color: #fff;
}.social-link h3:hover {
    color: #fff;
}
.social-link:hover {
    border: 1px solid #261fff;
}
.icon {
    color: #261fff;
}
.icon:hover {
    color: #fff;
}
label {
    color: black;
    margin-bottom: 0.5rem;
}
.loading {
    font-size: 1.2rem;
    font-weight: 600;
    color: black;
    text-align: center;
    margin-top: 2rem;
}
.loading p {
    color: black;
}
.success {
    font-size: 1.2rem;
    font-weight: 600;
    color: #261fff;
    text-align: center;
    margin-top: 2rem;
}
.error {
    font-size: 1.2rem;
    font-weight: 600;
    color: #ff9700;
    text-align: center;
    margin-top: 2rem;
}
form {
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 4rem 1rem;
    margin: auto;
    max-width: 600px;
}
.form:hover {
    border: 1px solid #261fff;
    border-radius: 10px;
}
form input, form textarea {
    margin-bottom: 1rem;
    padding: 10px 18px;
    font-size: 1.2rem;
    background-color: lightgray;
    border-color: lightgray;
    border-radius: 10px;
    color: black;
}

@media screen and (max-width: 767px) {
    .contact-container {
        grid-template-columns: 1fr;
    }
    .social-link  {
        margin-top: 1rem;
        max-width: 100%;
    }
}