.autobiography {
    border-radius: 10px;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 1rem;
    max-width: 80%;
}

.autobiography p {
    color: black;
    font-size: medium;
    line-height: 1.8;
    margin-bottom: 1em;
}

@media screen and (max-width: 767px) {
    .autobiography{
        background-color: #eeece8;
        margin: 1rem;
        max-width: 100%;
        padding: 1rem;
    }
}