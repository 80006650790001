.skills-heading {
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    padding: 4rem 0 2rem 0;
}
.skills-heading:hover {
    color: #ff9700;
}
.skills-container {
    max-width: 800px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    padding-bottom: 5rem;
}
.skills-card {
    text-align: center;
    border: 1px solid #ff9700;
    border-radius: 10px;
    background: #fff;
    padding: 1.2rem 1rem;
}
.skills-card:hover {
    cursor: pointer;
    border: 1px solid #261fff;
    border-radius: 10px;
    padding: 1.3rem 1.1rem;
    transition: 0.3s;
}
.skills-title {
    text-align: center;
}
.skills-title:hover {
    color: #ff9700;
    font-size: 1.7rem;
}
.skills li {
    padding: 0.1rem;
}
.skills li:hover {
    color: #261fff;
    font-size: 1.4rem;
}

@media screen and (max-width: 480px) {
    .skills-container {
        width: 80%;
        margin: auto;
        grid-template-columns: 1fr;
    } 
    .skills-btns {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0.5rem 0;
        margin: auto;
    } 
    .skills-btns .btn {
        margin-bottom: 1rem;
    }  
}

@media screen and (max-width: 767px) and ( min-width: 480px)  {
    .skills-container {
        width: 80%;
        height: 100%;
        margin: auto;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(2, 1fr);
    }
    .skills-btns .btn {
        margin-bottom: 1rem;
    } 
    .skills-btns {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: auto;
        padding: 0.5rem 0;
    }   
}