.footer {
    width: 100%;
    padding: 6rem 0;
    background-color: rgba(95, 66, 3, 0.9);
}
.footer-container {
    max-width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
}
/* Left column */
.left {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}
.location {
    display: flex;
    margin-bottom: 0.8rem;
}
.location p,
.phone h4,
.email h4  {
    color: #fff;
}

/* Right column */
.right {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}
.right h4, p {
    color: #fff;

}
h4 {
    font-size: 1.2rem;
    padding-bottom: 0.7rem;
}
p {
    font-size: 1.2rem;
}
.socials {
    margin-top: 1rem;
}
.footer-bottom {
    align-items: center;
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

@media screen and (max-width: 767px) {
    .footer {
        width: 100%;
        border-radius: 10px;
        margin: 1rem;
    }
    .footer-container {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        flex-direction: column;
    }
    .location p,
    .phone h4,
    .email h4 {
        font-size: 1rem;
    }
    .right h4, p {
        font-size: 1rem;
    }
}