.certificate-heading {
    text-align: center;
    padding: 4rem 0 2rem 0;
}
.certificate-heading:hover {
    color: #ff9700;
}
.certificate-container {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    padding-bottom: 5rem;
}

.certificate-card {
    /* border: 1px solid #ff9700; */
    border-radius: 10px;
    background: #fff;
    padding: 1.2rem 1rem;
    display: flex;
    flex-direction: column; /* Stacks elements vertically */
    justify-content: space-between; /* Distributes space between children */
    height: 100%; /* Ensures all cards have the same height */
}
.certificate-card:hover {
    border: 1px solid#261fff;
    border-radius: 10px;
}
.certificate-card img {
    border-radius: 10px;
    width: 100%;
    height: 217px;
}
.certificate-card img:hover {
    opacity: 0.3;
}
.certificate-date {
    color: #ff9700;
    padding: 1rem 0;
    font-size: 1.1rem;
    text-align: center;
}
.certificate-title {
    color: black;
    padding: 1rem 0;
    text-align: center;
}
.certificate-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.certificate-details p {
    color: black;
    padding-bottom: 1rem;
    font-size: 1.1rem;
    text-align: justify;
}
.certificate-btns {
    margin-top: auto; /* Pushes buttons to the bottom of the container */
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    gap: 10px; /* Adds spacing between buttons */
}
.certificate-btns .btn-light {
    text-align: center;
    color: #ff9700;
}.certificate-btns .btn-light:hover {
    color: #fff;
}

@media screen and (max-width: 480px) {
    .certificate-container {
        max-width: 80%;
        margin: auto;
        /* display: flex;
        flex-direction: column; */
        grid-template-columns: 1fr;
    } 
    .certificate-btns {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0.5rem 0;
        margin: auto;
    }
    .certificate-btns .btn {
        margin-bottom: 1rem;
    }
    .certificate-card {
        max-width: 100%;
        height: 100%; 
    } 
    .certificate-btns .btn-light {
        text-align: center;
    }   
}

@media screen and (max-width: 767px) and ( min-width: 480px) {
    .certificate-container {
        max-width: 90%;
        height: 100%;
        margin: auto;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(2, 1fr);  
    } 
    .certificate-btns {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: auto;
        padding: 0.5rem 0;
    } 
    .certificate-btns .btn {
        margin-bottom: 1rem;
    }
    .certificate-card {
        max-width: 100%;
        height: 100%;   
    }
}