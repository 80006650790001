@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {   
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

body {
  background: #ffff;
  /* margin: 0 4rem; */
  /* margin-left: 4rem;
  margin-right: 4rem; */
}

/* Logo */
/* .logo {
  width: 100px;
  height: 100px;
} */

/* Global Styles that will apply to the entire webpage */
h1,h2,h3,h4,p,a {
  color: black;
  text-decoration: none;
}

ul {
  list-style-type: none;
}

.btn {
  border-radius: 10px;
  padding: 12px 12px;
  font-size: 1rem;
  text-transform: uppercase;
  background: #261fff;
  color: #fff;
  border: 1px solid #ff9700;
  font-weight: 600;
  cursor: pointer;
}

.btn-light {
  border-radius: 10px;
  background: transparent;
  color: #fff;
}

.btn:hover {
  background: #ff9700;
  color: #ffff;
  transition: 0.3s;
}

/* 404 page */
.error-page {
  max-width: 600px;
  align-items: center;
}
.error-logo {
  display: flex;
  margin: auto;
  width: 150px;
  height: 150px;
}
.error-text {
  text-align: center;
  font-size: 1.4rem;
}