.hero-img {
    width: 100%;
    background: rgba(95, 66, 3, 0.9);
    height: 60vh;
    position: relative;
    opacity: 0.9;
}
.hero-img::before {
    content: "";
    background: url("../assets/images/banners/coding.png");
    background-size: cover;
    background-position: center top;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.2;
}
.heading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.hero-img h1 {
    color: #fff;
    font-size: 2.4rem;
    font-weight: 800;
}
.hero-img h1:hover {
    padding: 1rem;
    border: 5px solid #fff;
    border-radius: 10px;
}
.hero-img p {
    color: #fff;
    padding-top: 1rem;
    font-size: 1.4rem;
    text-align: center;
}

@media screen and (max-width: 640px) {
    .hero-img h1 {
        font-size: 2rem;
    }
}